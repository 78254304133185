//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "gift-guide-menu",
  props:{
    showMenu:{
      type: Boolean,
      required: true,
    },
    isMobilMenu:{
      type: Boolean,
      required: true,
    },
  },
  data(){
    return {
      listByGiftIdea: [],
      listByOccasion: [],
      active: true,
      occasionIsOpen: false,
      giftIdeaIsOpen: false
    }
  },
  async mounted(){
    const content = this.content = (await this.$axios.get(`/contents/gift-guide`)).data
    if(content && content.details && content.details.sections){
      const [giftGuide] = content.details.sections.filter(s => s.component === 'layout-gift-guide')
      if(giftGuide){
        this.listByGiftIdea = giftGuide.browsebygiftidea ? giftGuide.browsebygiftidea : []
        this.listByOccasion = giftGuide.browsebyoccasion ? giftGuide.browsebyoccasion : []
      }
    }
  },
}
