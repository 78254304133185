var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.openMenu),expression:"openMenu"}],staticClass:"collection-menu-wrapper mx-auto px-5",class:{ 'menu-wrapper-content': _vm.isSmallScreenUpperRange }},[_c('v-col',{attrs:{"cols":"3","offset-xl":"3","offset-lg":"1","offset-md":"1"}},[_c('div',{class:{
                           'menu-wrapper-content-xxl': _vm.isVeryLargeScreen,
                           'menu-wrapper-content-xl': _vm.$vuetify.breakpoint.xl,
                           'menu-wrapper-content-lg-high': _vm.isLargeScreenHighRange,
                           'menu-wrapper-content-lg-med-high': _vm.isLargeScreenUpperMediumRange,
                           'menu-wrapper-content-log-small-high': _vm.isLargeScreenLowerMediumRange,
                           'menu-wrapper-content-lg-med': _vm.isLargeScreenMediumRange,
                           'menu-wrapper-content-lg-low': _vm.isLargeScreenLowRange,
                           'menu-wrapper-content-md-s': _vm.isVloraLogoSmall,
                           'menu-wrapper-content-md': _vm.isSmallScreen,
                          }},[_c('label',{staticClass:"parent-collection-item"},[_vm._v("Engagement Rings")]),_vm._v(" "),_c('nuxt-link',{staticClass:"view-all-menu-item px-1 mt-4 mb-1",attrs:{"to":("/product-list?viewAll=" + (_vm.productSubCategoryCodes.EngagementRings))},nativeOn:{"click":function($event){return _vm.closeMenu($event)}}},[_vm._v("View All")]),_vm._v(" "),_c('v-list',_vm._l((_vm.engagementRings),function(collection){return _c('v-row',{staticClass:"collection-menu-item px-4",on:{"click":_vm.closeMenu}},[_c('nuxt-link',{attrs:{"to":"product-list?type=" + (_vm.productSubCategoryCodes.EngagementRings) + "&code=" + collection.code}},[_vm._v("\n                  "+_vm._s(collection.name.substring(0, 1) + collection.name.substring(1).toLowerCase())+"\n                ")])],1)}),1)],1)]),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('label',{staticClass:"parent-collection-item"},[_vm._v("Wedding Bands")]),_vm._v(" "),_c('nuxt-link',{staticClass:"view-all-menu-item px-1 mt-4 mb-1",attrs:{"to":("/product-list?viewAll=" + (_vm.productSubCategoryCodes.WeddingBands))},nativeOn:{"click":function($event){return _vm.closeMenu($event)}}},[_vm._v("View All")]),_vm._v(" "),_c('v-list',_vm._l((_vm.weddingBands),function(collection){return _c('v-row',{staticClass:"collection-menu-item px-4",on:{"click":_vm.closeMenu}},[_c('nuxt-link',{attrs:{"to":"product-list?type=" + (_vm.productSubCategoryCodes.WeddingBands) + "&code=" + collection.code}},[_vm._v("\n                  "+_vm._s(collection.name)+"\n                ")])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }