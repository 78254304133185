import { render, staticRenderFns } from "./gift-guide-menu.vue?vue&type=template&id=645d29ed&scoped=true&"
import script from "./gift-guide-menu.vue?vue&type=script&lang=js&"
export * from "./gift-guide-menu.vue?vue&type=script&lang=js&"
import style0 from "./gift-guide-menu.vue?vue&type=style&index=0&id=645d29ed&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645d29ed",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../MultiSiteClient/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VExpandTransition,VIcon,VImg})
