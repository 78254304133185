//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CollectionMenu from '@/frontend/components/common/collection-menu'
import GiftGuideMenu from "@/frontend/components/common/gift-guide-menu";
import {
  productSubCategoryCodes,
  productCategoryCodes
} from "@/frontend/enums/productCategoryCodes";
import RingsAndBandsMenu from "@/frontend/components/common/rings-and-bands-menu.vue";
import {productCategoriesMixin} from "@/frontend/mixins/productCategoriesMixin";

export default {
  name: 'Header',
  mixins: [productCategoriesMixin],
  components: {
    RingsAndBandsMenu,
    CollectionMenu,
    GiftGuideMenu
  },
  computed: {
    productCategoryCodes() {
      return productCategoryCodes
    },
    productCategorySubCodes() {
      return productSubCategoryCodes
    }
  },
  data: () => {
    return {
      isSticky: false,
      header_menus: [
        {
          label: 'Engagement Rings',
          link: `/product-list?viewAll=${productSubCategoryCodes.EngagementRings}`,
          code: 'eng-ring',
        },
        {
          label: 'Wedding Bands',
          link: `/product-list?viewAll=${productSubCategoryCodes.WeddingBands}`,
          code: 'wed-band',
        },
        {
          label: 'Fine Jewelry',
          link: `/product-list?viewAll=${productCategoryCodes.Collection}`,
          code: 'collections',
        },
        {
          label: 'Categories',
          link: '/categories',
          code: 'categories',
        },
        {
          label: 'Gift Guide',
          link: '/gift-guide',
          code: 'gift-guide'
        },
        {
          label: 'Bespoke',
          link: '/bespoke-page',
        },
        {
          label: 'Our story',
          link: '/our-story',
          code: 'ourstory',
        },
        {
          label: 'Find a store',
          link: '/find-a-store',
          code: 'find-a-store',
          icon: 'mdi-map-marker',
        },
      ],
      services: [
        {
          title: 'About Us',
          link: '/our-story',
        },
        {
          title: 'Community Responsibility',
          link: '/community-responsibility',
        },
      ],
      shopByCategory: [
        {
          title: 'Bangle & Bracelets',
          link: '/product-list?type=category&code=Bangles',
        },
        {
          title: 'Earrings',
          link: '/product-list?type=category&code=Earrings',
        },
        {
          title: 'Necklace & Pendants',
          link: '/product-list?type=category&code=Necklaces',
        },
        {
          title: 'Rings',
          link: '/product-list?type=category&code=Rings',
        },
      ],
      showCollectionSubmenu: false,
      showRingAndBandSubmenu: false,
      showOurStorySubmenu: false,
      showGiftGuideMenu: false,
      mobileMenuIsOpen: false,
      selectedMenuItemCode: null,
      collections: [],
      categories: [
        {
          name: 'Bangle & Bracelets',
          code: productSubCategoryCodes.BanglesBracelets,
        },
        {
          name: 'Earrings',
          code: productSubCategoryCodes.Earrings,
        },
        {
          name: 'Necklace & Pendants',
          code: productSubCategoryCodes.NecklacesPendants,
        },
        {
          name: 'Rings',
          code: productSubCategoryCodes.Rings,
        },
      ],
      queryString: '',
      searchOpen: false,
    }
  },
  mounted() {
    this.$axios
      .$get('productcategories/categorieswithchild/ShopCollection')
      .then(async (result) => {
        if (result.hasErrors) {
          this.$toast.error(result.errorMessages.join('</br>'))
        } else {
          this.collections = _.orderBy(result.value, 'name', 'asc')
        }
      })
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('touchmove', this.onTouchMove)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('touchmove', this.onTouchMove)
  },
  methods: {
    showMobilMenuArrow(code) {
      return code === 'collections' || code === 'categories' || code === 'ourstory' || code === 'gift-guide' || code === 'eng-ring' || code === 'wed-band'
    },
    openSubmenu(link) {
      if (link) {
        window.location.href = link
      }
    },
    closeMobileMenu() {
      this.mobileMenuIsOpen = false
    },
    openMobileSubMenu(code) {
      this.selectedMenuItemCode =
        this.selectedMenuItemCode === code ? null : code
    },
    search() {
      if (!this.queryString) {
        return
      }
      this.$router.push({
        path: '/product-list',
        query: {keyword: this.queryString},
      })
    },
    mouseOver: function (e, code) {
      this.showOurStorySubmenu =false;
      this.showCollectionSubmenu = false;
      this.showRingAndBandSubmenu = false;
      this.showGiftGuideMenu =false;
      if (code === 'collections') {
        this.showCollectionSubmenu = true;
        this.selectedMenuItemCode =
            this.selectedMenuItemCode === code ? null : code

      }
      if (code === 'eng-ring' || code === 'wed-band') {
        this.showRingAndBandSubmenu = true;
        this.selectedMenuItemCode =
            this.selectedMenuItemCode === code ? null : code
      }
      if (code === 'ourstory') {
        this.showOurStorySubmenu = true;
      }
      if(code === 'gift-guide'){
        this.showGiftGuideMenu = true
      }

    },
    mouseLeaveNav: function (e) {
      if(e.relatedTarget)
        console.log(e.relatedTarget.classList)
      if (e.fromElement && e.fromElement.children && e.fromElement.children[0] &&
        e.toElement && e.toElement.classList &&
        e.fromElement.children[0].classList.contains('menu-wrapper') &&
        e.toElement.classList.contains('v-responsive__sizer')) {
        this.closeAllMenu()
        return
      }
      if (e.relatedTarget &&
         (e.relatedTarget && e.relatedTarget.classList.contains('collection-menu-wrapper')
          || e.relatedTarget && e.relatedTarget.classList.contains('gift-guide-menu-wrapper')
          || e.relatedTarget && e.relatedTarget.classList.contains('parent-collection-item')
          || e.relatedTarget && e.relatedTarget.classList.contains('browse')
           || e.relatedTarget && e.relatedTarget.classList.contains('submenu-item')
           || e.relatedTarget && e.relatedTarget.classList.contains('submenu-list')
           || e.relatedTarget && e.relatedTarget.classList.contains('our-story'))) {
        return
      }
      if (e.target && e.target.children && e.target.children[0].classList &&
        (e.target.children[0].classList.contains('header-menu-item'))) {
        return
      }
      this.closeAllMenu()
    },
    closeAllMenu() {
      this.showOurStorySubmenu = false;
      this.showCollectionSubmenu = false;
      this.showGiftGuideMenu = false;
      this.showRingAndBandSubmenu = false;
    },
    onTouchMove(e){
      if(e && e.view && e.view.document){
        const topScroll = e.view.document.querySelector("html").scrollTop
        this.isSticky = topScroll > 0
      }
    },
    onScroll(e) {
      const topScroll = document.querySelector("html").scrollTop
      this.isSticky = topScroll > 0
    },
    async openSearch() {
      this.searchOpen = !this.searchOpen
      await this.$nextTick();
      if(!this.searchOpen) return;
      const searchInputRef = this.$refs.searchInput;
      searchInputRef.focus();
    },
  },
}
