//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {productSubCategoryCodes, productCategoryCodes} from '@/frontend/enums/productCategoryCodes'
export default {
  name: "collection-menu",
  props:{
    collections:{
      type: Array
    },
    showmenu:{
      type: Boolean
    },
  },
  watch:{
    collections(newValue){
      if(newValue){
        this.shopByCollections = newValue
      }
    },
    showmenu(newValue){
      this.openMenu = newValue
    }
  },
  data(){
    return {
      openMenu: false,
      shopByCollections: [],
      shopByCategory: [
        {
          name: 'Bangle & Bracelets',
          code: productSubCategoryCodes.BanglesBracelets,
        },
        {
          name: 'Earrings',
          code: productSubCategoryCodes.Earrings,
        },
        {
          name: 'Necklace & Pendants',
          code: productSubCategoryCodes.NecklacesPendants,
        },
        {
          name: 'Rings',
          code: productSubCategoryCodes.Rings,
        },
      ],
    }
  },
  computed: {
    productCategoryCodes(){
      return productCategoryCodes
    }
  },
  methods:{
    getImage(collectionCode){
      const collection = this.shopByCollections.find(c => c.code === collectionCode)
      return collection ? collection.imageURL : ''
    },
    closeMenu(){
      this.openMenu = false
    },
  }
}
