//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  productSubCategoryCodes
} from '@/frontend/enums/productCategoryCodes'
export default {
  name: "rings-and-bands-menu",
  props:{
    showmenu:{
      type: Boolean
    },
    engagementRings: {
      type: Array,
    },
    weddingBands: {
      type: Array,
    }
  },
  computed:{
    productSubCategoryCodes(){
      return productSubCategoryCodes
    },
    /// Sry, de muszáj volt :(
    isVeryLargeScreen(){
      return this.$vuetify.breakpoint.width > 2000;
    },
    isLargeScreenHighRange(){
      return this.$vuetify.breakpoint.width >= 1750 && this.$vuetify.breakpoint.width <= 1906;
    },
    isLargeScreenUpperMediumRange(){
      return this.$vuetify.breakpoint.width >= 1650 && this.$vuetify.breakpoint.width <= 1750;
    },
    isLargeScreenLowerMediumRange(){
      return this.$vuetify.breakpoint.width >= 1500 && this.$vuetify.breakpoint.width <= 1650;
    },
    isLargeScreenMediumRange(){
      return this.$vuetify.breakpoint.width >= 1440 && this.$vuetify.breakpoint.width <= 1500;
    },
    isLargeScreenLowRange(){
      return this.$vuetify.breakpoint.width >= 1300 && this.$vuetify.breakpoint.width <= 1440;
    },
    // no comment
    isVloraLogoSmall(){
      return this.$vuetify.breakpoint.width >= 1265 && this.$vuetify.breakpoint.width < 1300;
    },
    isSmallScreenUpperRange()
    {
      return this.$vuetify.breakpoint.width < 1265;
    },
    isSmallScreen(){
      return this.$vuetify.breakpoint.width < 1150;
    },
  },
  watch:{
    showmenu(newValue){
      this.openMenu = newValue
    }
  },
  data(){
    return {
      openMenu: false,
      shopByCollections: [],
    }
  },
  async mounted(){
  },
  methods:{
    closeMenu(){
      this.openMenu = false
    },
  }
}
